// load all images from theme_url/img
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./img', true));

// import bootstrap js
// import './js/bootstrap.js';
import './js/leaflet.js';

// custom scss
import './scss/app.scss';

$('.btn.menu').bind('click', () => {
    $(this).toggleClass('collapsed');
    $('body').toggleClass('menu-open');
})
$('.page-content-holder, footer, .navbar-brand').bind('click', (ev) => {
    if($('body').hasClass('menu-open')){
        ev.preventDefault();
        $('.btn.menu').toggleClass('collapsed');
        $('body').toggleClass('menu-open');
    }
})